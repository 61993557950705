.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em 6em;
  color: var(--portfolio-cream);
  margin-top: 1em;
  margin-bottom: 2em;
}

.projectLeft {
  text-align: center;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.projectRight {
  padding: 2em;
}

.projectRight p {
  text-align: justify;
}

.projectTitle {
  color: var(--portfolio-yellow);
  font-size: 2em;
  margin-top: 0;
}
.project h2 {
  color: var(--portfolio-yellow);
}

.projectImage {
  width: 80%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
}

.techIconsArea {
  display: flex;
  justify-content: center;
}

.techIcon {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: var(--portfolio-yellow);
  margin: 1em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
}

.projectLinks {
  display: flex;
  justify-content: center;
}

/* .projectLink {
  color: var(--portfolio-yellow);
  border-radius: 20px;
  padding: 0.5em 1em;
  margin: 2em;
  transition: ease 0.3s all;
  border: 2px solid var(--portfolio-yellow);
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
}

.projectLink:hover {
  color: black;
  font-weight: 400;
  transition: ease 0.3s all;
  border: 2px solid black;
  /* transform: scale(1.06); 
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 3px;
} 
*/

.projectLink {
  padding: 0.5em 1em;
  margin: 1em 1em 0;
  font-size: 1.4em;
  font-weight: 500;
  color: var(--portfolio-yellow);
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.projectLink:after {
  display: block;
  content: "";
  width: 0px;
  height: 2px;
  position: relative;
  background-color: var(--portfolio-yellow);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  opacity: 0.9;
}

.projectLink:hover {
  color: var(--portfolio-yellow);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  text-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

.projectLink:hover:after {
  width: 100%;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

.projectSeperator {
  width: 80%;
  margin: auto;
  height: 1px;
  background-color: var(--portfolio-dark-gray);
  border: none;
}

@media only screen and (max-width: 900px) {
  .project {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 1em;
    margin-top: 0;
    color: var(--portfolio-cream);
  }

  .projectTitle {
    margin-top: 1em;
  }

  .projectLeft {
    padding: 0.5em 0;
  }
  .projectRight {
    padding: 0 1em;
  }

  .projectLink {
    padding: 0.5em 1em;
    font-size: 1.1em;
  }
}
