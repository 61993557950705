.aboutPage h1 {
  margin-top: 0;
  text-align: center;
  color: var(--portfolio-cream);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.aboutContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  max-width: 100%;
}

.profileImage {
  width: 100%;
  max-width: 500px;
  margin: 1em auto;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 3px;
}

.aboutPage {
  margin-top: 0;

  padding: 6em 4em 4em;
  text-align: left;
}

.aboutPage p {
  width: 80%;
  margin: 1em auto;
  color: var(--portfolio-cream);
  white-space: pre-line;
}

/* .aboutPage a {
  color: var(--portfolio-yellow);
  text-decoration: none;
}

.aboutPage a:visited {
  color: var(--portfolio-light-gray);
} */

.aboutPage a {
  color: var(--portfolio-yellow);
  text-decoration: none;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  display: inline-block;
}

.aboutPage a:after {
  display: block;
  content: "";
  width: 0px;
  height: 2px;
  position: relative;
  background-color: var(--portfolio-yellow);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  opacity: 0.9;
}

.aboutPage a:hover {
  color: var(--portfolio-yellow);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  text-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

.aboutPage a:hover:after {
  width: 100%;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

@media only screen and (max-width: 600px) {
  .aboutPage {
    padding: 4em 2em;
  }

  .aboutContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .aboutPage p {
    width: 90%;
  }

  .profileImage {
    float: none;
    width: 90%;
    max-width: 90%;
    /* max-height: 25vh; */
    margin-left: 5vw;
    margin-right: 5vw;
  }
}
