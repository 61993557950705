:root {
  --portfolio-dark-brown: #423e37;

  --portfolio-yellow: #e3b23c;

  --portfolio-cream: #edebd7;

  --portfolio-light-gray: #a39594;

  --portfolio-dark-gray: #6e675f;

  font-size: 16px;
}

@media only screen and (min-width: 2000px) {
  :root {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1400px) {
  :root {
    font-size: 15px;
  }
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  width: auto;
  background-color: var(--portfolio-dark-brown);
}

::-webkit-scrollbar-track {
  background: var(--portfolio-dark-brown);
  border-radius: 100vw;
  margin-block: 0.2em;
}

::-webkit-scrollbar-thumb {
  background: var(--portfolio-yellow);
  border-radius: 100vw;
  border: 4px solid var(--portfolio-dark-brown);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--portfolio-cream);
}

@supports (
  scrollbar-color: var(--portfolio-dark-gray) var(--portfolio-yellow)
) {
  * {
    scrollbar-color: var(--portfolio-yellow) var(--portfolio-dark-brown);
    scrollbar-width: thin;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
