.projectCard {
  max-width: 100%;
  margin: 2em;
  padding: 1em;
  background-color: var(--portfolio-dark-gray);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
  display: grid;
  grid-template-rows: 4fr 1fr;
}

.projectCard h3 {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.cardTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  justify-content: center;
  /* align-items: center; */
}
.cardBottom {
  display: flex;
}

.projectText {
  background-color: var(--portfolio-cream);
  color: var(--portfolio-dark-brown);
  padding: 2em;
  border-radius: 10px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
}

.projectText > h3 {
  text-align: center;
}

.projectText p {
  text-align: justify;
}

.projectImageDiv {
  display: flex;
  justify-content: center;
}

.projectImage {
  max-width: 100%;
  /* border: 3px solid var(--portfolio-cream); */
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
}

.projectLinks {
  display: flex;
  justify-content: center;
}

.projectLink {
  background-color: var(--portfolio-yellow);
  color: var(--portfolio-dark-brown);
  border-radius: 3px;
  padding: 0.5em 1em;
  margin: 2em;
  transition: ease 0.3s all;
  border: 2px solid var(--portfolio-yellow);
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
  text-transform: uppercase;
  font-weight: 600;
}

.projectLink:hover {
  background-color: var(--portfolio-cream);
  color: black;
  font-weight: 600;
  transition: ease 0.3s all;
  border: 2px solid var(--portfolio-yellow);
  /* transform: scale(1.06); */
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 3px;
}

@media only screen and (max-width: 1200px) {
  .projectCard {
    /* border: 1px solid var(--portfolio-yellow); */
    margin: 2em;
    padding: 1em;
  }

  .cardTop {
    grid-template-columns: 1fr;
    grid-gap: 0em;
  }

  .projectText {
    padding: 1em;
    margin-top: 0em;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .projectImageDiv .projectImage {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .projectLink {
    padding: 0.4em 1em;
    font-size: 0.8em;
  }
}
