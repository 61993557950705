.contactForm {
  border: 3px solid var(--portfolio-dark-brown);
  background-color: var(--portfolio-dark-brown);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 50em;
  margin: 2em auto;
  padding: 2em;
  align-items: center;
  text-align: left;
  color: var(--portfolio-cream);
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
}

.contactForm h3 {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.contactForm label {
  align-self: flex-start;
  margin-left: 1em;
  color: var(--portfolio-yellow);
}

.contactForm textArea {
  font-family: inherit;
}

.nameInput,
.emailInput,
.messageInput {
  border-radius: 10px;
  padding: 1em;
  border: none;
  margin: 1em 0;
  width: 90%;
  max-width: 40em;
  color: var(--portfolio-dark-brown);
  background-color: var(--portfolio-cream);
}

.submit {
  background-color: var(--portfolio-yellow);
  color: var(--portfolio-dark-brown);
  border-radius: 3px;
  padding: 0.5em 2em;
  margin: 2em;
  transition: ease 0.3s all;
  border: 2px solid var(--portfolio-yellow);
  box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px;
  text-transform: uppercase;
  font-weight: 600;
}

.submit:hover {
  background-color: var(--portfolio-cream);
  color: black;
  font-weight: 600;
  transition: ease 0.3s all;
  border: 2px solid var(--portfolio-yellow);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 3px;
}

.thanks {
  border: 3px solid var(--portfolio-yellow);
  padding: 2em;
  height: 2em;
  background-color: var(--portfolio-dark-brown);
  border-radius: 10px;
  visibility: visible;
}

.notSent {
  visibility: hidden;
}
