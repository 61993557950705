.navbar {
  display: flex;
  justify-content: flex-end;
  background-color: var(--portfolio-dark-brown);
  color: var(--portfolio-yellow);
  padding: 1em;
  font-size: 1.3em;
  font-weight: 600;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.navlist {
  list-style-type: none;
  display: flex;
  margin: 0 2em;
}

.navlist > li {
  margin: 0 2em;
}

.navlist a {
  color: var(--portfolio-yellow);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.navlist a:after {
  display: block;
  content: "";
  width: 0px;
  height: 2px;
  position: relative;
  /* bottom: 0.37em; */
  /* bottom: 1em; */
  background-color: var(--portfolio-yellow);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  opacity: 0.9;
}

.navlist a:hover {
  color: var(--portfolio-yellow);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  text-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

.navlist a:hover:after {
  width: 100%;
  box-shadow: 1px 1px 1px rgba(17, 17, 17, 0.788);
}

@media only screen and (max-width: 600px) {
  /* .navbar {
    display: none;
  } */
  .navbar {
    max-width: 100vw;
  }

  .navlist > li {
    margin: 0 0.5em;
  }

  .navlist a {
    font-size: 0.8em;
  }
}
