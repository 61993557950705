.techIcon {
  width: 3em;
  height: 3em;
  /* box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 8px; */
  /* border-radius: 50%; */
}

.techIconWithName {
  display: block;
  margin: 0.5em;
}

.techIconWithName .techNameDisplay {
  margin-top: 0;
  font-size: 0.8em;
  color: var(--portfolio-yellow);
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .techIcon {
    width: 2em;
    height: 2em;
  }

  .techIconWithName {
    display: block;
    margin: 0.3em;
  }

  .techNameDisplay {
    margin-top: 0;
    font-size: 0.7em;
    color: var(--portfolio-yellow);
  }
}
