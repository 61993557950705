.header {
  background-color: var(--portfolio-dark-brown);
  background: url(../../images/retrofade.webp);
  background-size: cover;
  /* height: 110vh; */
  /* background-repeat: none;  */
  /* width: 100vw; */
  padding-top: 56.25%;

  color: var(--portfolio-cream);
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2em; */
}

.homepage {
  padding-bottom: 2em;
}

.textContainer {
  position: absolute;
  max-width: 100%;
  top: 5%;
  left: 50%;
  bottom: 0;
  right: 0;
  text-align: center;
}
.homepage h1 {
  font-size: 4em;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 700;
  letter-spacing: 0.05em;
}

.homepage h2 {
  color: var(--portfolio-cream);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.homepage .featuredHeader {
  font-size: 2.6em;
}

.subtitle {
  font-size: 2.4em;
  font-weight: 400;
  color: var(--portfolio-cream);
  margin-top: 0;
}

.textContainer {
  padding: 4em;
  max-height: 100%;
  /* background-color: rgba(66, 62, 55, 0.3); */
}

.pitchLandscape {
  font-size: 1.2em;
  display: block;
}

.pitchPortraitContainer {
  display: none;
}

.contactArea {
  background-color: var(--portfolio-cream);
  padding: 2em;
}

.contactContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
}

.contactArea h2 {
  color: var(--portfolio-dark-brown);
}

@media only screen and (max-width: 1200px) {
  .textContainer {
    top: 4%;
  }
}
@media only screen and (max-width: 1100px) {
  .textContainer {
    top: 2%;
  }
  .homepage h1 {
    font-size: 3.5em;
  }

  .homepage h2 {
    /* font-size: 2em; */
  }

  /* .pitchLandscape {
    font-size: 1.1em;
  } */
}

@media only screen and (max-width: 1100px) {
  body {
    font-size: 14px;
  }

  .homepage {
    padding-top: 4em;
  }

  .header {
    background-size: cover;
    background-repeat: none;
    /* width: 100%; */
    height: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 0;
  }

  .textContainer {
    padding: 1.8em;
    padding-right: 0.7em;
    top: 7%;
    left: 30%;
  }

  .homepage h1 {
    font-size: 3em;
    margin-top: 2em;
  }

  .homepage h2 {
    font-size: 1.6em;
  }

  .featuredHeader {
    margin-bottom: 0;
  }

  .homepage .subtitle {
    font-size: 1.6em;
    margin-bottom: 4em;
  }

  .pitchLandscape {
    display: none;
  }
  .pitchPortraitContainer {
    display: block;
    /* background-image: linear-gradient(
      to right top,
      #4a97b4,
      #4485a0,
      #3e738b,
      #386278,
      #315264
    ); */
    background-color: var(--portfolio-cream);
    margin: 1.5em;
    padding: 1em;
    border-radius: 10px;
    color: var(--portfolio-dark-brown);
    text-align: left;
  }
  .pitchPortrait {
    font-size: 0.9em;
    margin: 0.3em 0;
  }
}

@media only screen and (max-width: 960px) {
  .homepage h1 {
    font-size: 3em;
    margin-top: 2em;
  }

  .homepage .featuredHeader {
    font-size: 2.2em;
  }
}

@media only screen and (max-width: 700px) {
  .homepage h1 {
    font-size: 2.4em;
    margin-top: 2em;
  }
  .homepage .subtitle {
    font-size: 1.6em;
    margin-bottom: 4em;
  }

  .contactContainer {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 550px) {
  .homepage h1 {
    font-size: 1.8em;
    margin: 0.5em 0;
  }
  .homepage .subtitle {
    font-size: 1.2em;
    margin-bottom: 4em;
  }
}
@media only screen and (max-width: 400px) {
  .homepage h1 {
    font-size: 1.7em;
    margin: 1em 0 0.3em;
  }
  .homepage .subtitle {
    font-size: 1.1em;
    margin-bottom: 4em;
  }
}
